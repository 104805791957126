const DoubleScroll = (element) => {
  const scrollbar = document.createElement('div');

  scrollbar.appendChild(document.createElement('div'));
  scrollbar.style.overflow = 'auto';
  scrollbar.style.overflowY = 'hidden';
  scrollbar.firstChild.style.width = element.scrollWidth + 'px';
  scrollbar.firstChild.style.paddingTop = '1px';
  scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));

  scrollbar.onscroll = function () {
    element.scrollLeft = scrollbar.scrollLeft;
  };

  element.onscroll = function () {
    scrollbar.scrollLeft = element.scrollLeft;
  };

  element.parentNode.insertBefore(scrollbar, element);
}

const tables = document.querySelectorAll('.table, .table-two, .table-three');
if (tables.length > 0) {
  console.log(tables);
  tables.forEach((table) => {
    DoubleScroll(table);
  });
}
